import { Route, Routes, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react"
import { Layout } from "antd"
import * as Styles from './styles'
import { Sidebar } from "src/components/Sidebar"
import { BreadCrumb } from "src/components/BreadCrumb"
import { SideMenu } from "src/components/Drawer"
import { toggleDrawerOpenStatus } from "src/components/Sidebar/state/action"
import { Navbar } from "src/components/Navbar"
import { Footer } from "src/components/Footer"
import { Dashboard } from "src/features/dashboard"
import { Urls } from "src/common/urls"
import { Products } from "src/features/Product/Products"
import { CreateProduct } from "src/features/Product/CreateProduct"
import { CreatePurchase } from "src/features/Purchase/CreatePurchase"
import { CreateSale } from "src/features/Sale/CreateSale"
import { WarehouseStock } from "src/features/Product/WarehouseStock"
import { PurchaseRecords } from "src/features/Purchase/PurchaseRecords"
import { SaleRecords } from "src/features/Sale/SaleRecords"
import { ProductsKardex } from "src/features/Reports/SaleDetail/ProductsKardex"
import { UnitsSold } from "src/features/Reports/SaleDetail/UnitsSold"
import { getSessionData } from "src/session/sessionStore"
import { loginSuccess } from "src/features/Security/state/action"
import { User, UserRoles } from "src/common/models/user"
import { RootState } from "src/state/reducer"
import { AccountPayables } from "src/features/Purchase/AccountPayables"
import { AccountReceivables } from "src/features/Sale/AccountReceivables"
import { Company } from "src/common/models/company"
import { getCompanyByIdApi } from "src/common/state/action"
import { CreateRoutingSale } from "src/features/Routing/CreateSale"
import { DashboardItems } from "src/features/dashboard/types"
import { Settings } from "src/features/Settings/Settings"
import { SalesReportPage } from "src/features/Reports/Sales/Sales"
import { UnitsSoldUtility } from "src/features/Reports/SaleDetail/UnitsSoldUtility"

interface ReduxProps {
    user?: User
    company?: Company
}

export const AdminRouter = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [dashboardItemsToShow, setDashboardItemsToShow] = useState<DashboardItems[]>([])

    const reduxProps: ReduxProps = useSelector((state: RootState) => ({
        user: state.security.user,
        company: state.common.company,
    }))

    useEffect(() => {
        if (!reduxProps.user) {
            const user = getSessionData().user
            if (user) {
                dispatch(loginSuccess(user))
            } else {
                navigate(Urls.FRONTEND.LOGOUT)
            }
        }

        if (!reduxProps.company) {
            dispatch(getCompanyByIdApi(1))
        }

        // if (!reduxProps.settings) {
        //     dispatch(getSettings())
        // }
    }, [])

    useEffect(() => {
        if (!reduxProps.user) {
            return
        }

        switch (reduxProps.user.userTypeId) {
            case UserRoles.ADMIN:
                setDashboardItemsToShow([
                    DashboardItems.ALL,
                ])
                break;

            case UserRoles.SPECIAL:
            case UserRoles.BASIC:
                setDashboardItemsToShow([
                    DashboardItems.COMPANY_DATA,
                    DashboardItems.SHORTCUTS,
                ])
                break;

            default:
                break;
        }
    }, [reduxProps.user])


    const renderRoutes = () => {
        return <Styles.ContentContainer>
            <Routes>
                <Route path={Urls.FRONTEND.SALE.CREATE} element={<CreateSale />} />
                <Route path={Urls.FRONTEND.WAREHOUSE_STOCK.RECORDS} element={<WarehouseStock />} />
                <Route path={Urls.FRONTEND.SALE.RECORDS} element={<SaleRecords />} />

                <Route path={Urls.FRONTEND.PRODUCT.PRODUCTS} element={<Products />} />
                <Route path={Urls.FRONTEND.PRODUCT.CREATE} element={<CreateProduct />} />
                <Route path={Urls.FRONTEND.PRODUCT.EDIT} element={<CreateProduct />} />
                <Route path={Urls.FRONTEND.PURCHASE.CREATE} element={<CreatePurchase />} />
                <Route path={Urls.FRONTEND.PURCHASE.RECORDS} element={<PurchaseRecords />} />
                <Route path={Urls.FRONTEND.PURCHASE.PAYABLES} element={<AccountPayables />} />
                <Route path={Urls.FRONTEND.SALE.RECEIVABLES} element={<AccountReceivables />} />

                <Route path={Urls.FRONTEND.ROUTING.CREATE_SALE} element={<CreateRoutingSale />} />

                <Route path={Urls.FRONTEND.SETTINGS} element={<Settings />} />

                <Route path={Urls.FRONTEND.REPORTS.KARDEX} element={<ProductsKardex />} />
                <Route path={Urls.FRONTEND.REPORTS.UNITS_SOLD} element={<UnitsSold />} />
                <Route path={Urls.FRONTEND.REPORTS.SALES_REPORT} element={<SalesReportPage />} />
                <Route path={Urls.FRONTEND.REPORTS.UNITS_SOLD_UTILITY} element={<UnitsSoldUtility />} />

                <Route path="*" element={<Dashboard itemsToShow={dashboardItemsToShow} />} />
            </Routes>
        </Styles.ContentContainer>
    }

    const renderDrawer = () => {
        return <SideMenu
            placement={'left'}
            closable={true}
            onClose={() => { dispatch(toggleDrawerOpenStatus(false)) }}
        >
            <Sidebar
                collapsible={false}
                collapsed={false}
            />
        </SideMenu>
    }

    return (
        <Layout>
            <Sidebar />
            {renderDrawer()}
            <Layout>
                <Navbar />
                <BreadCrumb />
                {renderRoutes()}
                <Footer />
            </Layout>
        </Layout>
    )
}
